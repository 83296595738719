import AutoLinkText from '@/components/AutoLinkText'
import {
  MOBILE_HEADER_DEFAULT_HEIGHT,
  PC_HEADER_DEFAULT_HEIGHT,
  PC_HEADER_DEFAULT_TOP,
  PC_HEADER_MAIN_HEIGHT,
  PC_HEADER_MAIN_TOP,
} from '@/constants/Layout'
import RouterMap from '@/constants/RouterMap'
import { ImageAsset } from '@/constants/StaticAsset'
import { CategoryType } from '@/enums/MetaContent'
import useMetaContent from '@/hooks/fetch/useMetaContent'
import useScrollEffect from '@/hooks/useScrollEffect'
import {
  STYLE_FONT_FAMILY,
  STYLE_FONT_SIZE,
  STYLE_FONT_WEIGHT,
  STYLE_KEEP_WORD,
  STYLE_LINE_HEIGHT,
} from '@/styles/fonts'
import { GLOBAL_HIDDEN_SCROLL_MOBILE_CLASS } from '@/styles/global'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ComponentStyledOptionalProps } from '@/types/Component'
import { CategoryItemAll } from '@/types/MetaContent'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import OnlyClient from '../OnlyClient'
import { ContentLogEventActionName, ContentLogPageName } from '@/enums/Content'
import { trackEvent } from '@/helpers/logger'
import useContentPathSegment from '@/hooks/useContentPathSegments'
import RenderOnly from '../RenderOnly'
import Icon, { IconTypeFile } from '../Icon'
import FocusLock from 'react-focus-lock'
import { hideOthers } from 'aria-hidden'
import { Hidden } from '@kakaomobility/tui-react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const INVERTED_LOGO = css`
  filter: invert(100%);
`

const SHRINK_LOGO = css`
  height: 17px;
`

const Logo = styled(AutoLinkText)`
  overflow: hidden;
  width: 1px;
  padding-left: 163px;
  height: 27px;
  text-indent: 1px;
  background-image: url(${ImageAsset.LOGO});
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.2s ease;
  transform-origin: 0 0;

  @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
    ${SHRINK_LOGO};
    margin: 0;
  }
`

const EXPANDED_MAIN_MENU_CHILDREN = css`
  background: white;
  padding: 32px;
  height: auto;
  box-shadow: 0px 4px 16px rgba(38, 45, 57, 0.16);
  border-radius: 8px;
`

const EXPANDED_DEFAULT_MENU_CHILDREN = css`
  background: white;
  padding: 32px;
  overflow: visible;
  height: auto;
  box-shadow: 0px 4px 16px rgba(38, 45, 57, 0.16);
  border-radius: 8px;
`

const MenuItem = styled(AutoLinkText)<{ isCurrentMenu: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isCurrentMenu }) =>
    isCurrentMenu ? STYLE_COLOR.BLACK01 : 'inherit'};
  font-size: ${STYLE_FONT_SIZE._14};
  line-height: ${STYLE_LINE_HEIGHT._30};
  text-decoration: none;
  white-space: nowrap;
  transition: color 0.1s ease;
  background: none transparent;
  border: 0 none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: ${STYLE_COLOR.BLACK01};
  }
`

const MenuChildren = styled.ul`
  display: none;
  height: 0;
  color: #808080;

  ${MenuItem} {
    display: block;
  }

  > li ~ li {
    margin-top: 16px;
  }
`

const WRAP_ACTIVE = css`
  background-color: ${STYLE_COLOR.WHITE01};
  border-bottom: 1px solid ${STYLE_COLOR.BLACK05};
  color: ${STYLE_COLOR.BLACK02};
`

const MobileTrigger = styled.button`
  position: relative;
  top: -2px;
  right: -8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
`

const MobileTriggerIcon = styled.span<LayoutHeaderStyleProps>`
  position: relative;
  display: block;
  width: 24px;
  height: 24px;

  &::before {
    top: 6px;
    ${({ expand }) =>
      expand &&
      css`
        transform: rotate(-45deg);
      `}
  }

  &::after {
    bottom: 6px;
    ${({ expand }) =>
      expand &&
      css`
        transform: rotate(45deg);
      `}
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    overflow: hidden;
    width: 24px;
    height: 0;
    transition: transform 0.25s ease-in-out;
    border-style: solid;
    border-width: 1px 0 1px;
    border-color: ${STYLE_COLOR.BLACK01};

    ${({ expand }) =>
      expand &&
      css`
        top: 50%;
        margin-top: -1px;
      `};
  }
`

const TRANSPARENT_MENU_WRAP = css`
  background-color: ${STYLE_COLOR.TRANSPARENT};
  color: ${STYLE_COLOR.WHITE01};
  border-bottom: none;
`

const NORMAL_MENU_WRAP = css`
  background-color: ${STYLE_COLOR.WHITE01};
  color: ${STYLE_COLOR.BLACK02};

  &::before {
    border-bottom-color: ${STYLE_COLOR.BLACK05};
  }
`

interface LayoutHeaderMobileStyleProps {
  expand?: boolean
  current?: boolean
  child?: boolean
}

type LayoutHeaderStyleProps = LayoutHeaderProps &
  LayoutHeaderMobileStyleProps & {
    scroll?: boolean
    control?: boolean
    isHeaderTransparentAtTop?: boolean
  }

interface LayoutHeaderProps extends ComponentStyledOptionalProps {
  shrink?: boolean
  isHeaderTransparentAtTop?: boolean
}

const useMenuLogging = () => {
  const logGNBClick = (item: { id: number; label: string }) => {
    trackEvent({
      pageName: ContentLogPageName.HOME,
      actionName: ContentLogEventActionName.HOME_GNB_LINK_CLICK,
      eventMeta: {
        id: `${item.id}`,
        name: item.label,
      },
    })
  }

  const logLNBClick = (item: { id: number; label: string }) => {
    trackEvent({
      pageName: ContentLogPageName.HOME,
      actionName: ContentLogEventActionName.HOME_LNB_LINK_CLICK,
      eventMeta: {
        id: `${item.id}`,
        name: item.label,
      },
    })
  }

  return {
    logGNBClick,
    logLNBClick,
  }
}

const useCheckCurrentMenu = (items: CategoryItemAll[]) => {
  const { pathname } = useRouter()
  const { pathSegments } = useContentPathSegment()
  const contentId = pathSegments[0]

  // contentId를 사용하지 않는 케이스는 따로 처리
  if (pathname.includes('notices')) {
    return { isCurrentMenu: items[0].id === 20 }
  }

  if (pathname.includes('newsroom')) {
    return { isCurrentMenu: items[0].id === 110 }
  }

  return {
    isCurrentMenu: items.some(
      (item) => contentId && contentId === item.contentId
    ),
  }
}

const Partial = {
  Menu({ categories, onLeave }, index) {
    if (!categories) {
      return null
    }

    const menuList = categories?.filter(
      (menu) => menu.type !== CategoryType.RIGHT_LINK
    )
    const rightMenuLink = categories?.filter(
      (menu) => menu.type === CategoryType.RIGHT_LINK
    )

    return (
      <Styled.Menu
        role='navigation'
        className='menuList'
        aria-label='메인 메뉴'
      >
        <ul className='menuList'>{menuList.map(Partial.MenuGroup)}</ul>
        {rightMenuLink.length > 0 && (
          <ul>{rightMenuLink.map(Partial.MenuGroup)}</ul>
        )}
      </Styled.Menu>
    )
  },
  MenuGroup(item, index, array) {
    const menuChildren = array.filter(
      (childCandidate) => childCandidate?.parentId === item.id
    )
    const { isCurrentMenu } = useCheckCurrentMenu([item, ...menuChildren])
    const { logGNBClick, logLNBClick } = useMenuLogging()
    const hasChildren = menuChildren?.length > 0

    // 자식 아이템의 경우 스킵
    if (item?.parentId !== null) {
      return null
    }

    return (
      <Styled.MenuGroup key={item.label} {...item}>
        <Styled.MenuTitle>
          <Partial.MenuItem
            item={item}
            isCurrentMenu={isCurrentMenu}
            onClick={() => logGNBClick(item)}
          />
        </Styled.MenuTitle>
        {hasChildren && (
          <Styled.MenuChildren>
            {menuChildren.map((childItem) => (
              <li key={childItem.label}>
                <Partial.MenuItem
                  item={childItem}
                  onClick={() => logLNBClick(childItem)}
                />
              </li>
            ))}
          </Styled.MenuChildren>
        )}
      </Styled.MenuGroup>
    )
  },
  MenuItem({ item, onClick, isCurrentMenu = false }) {
    if (!item?.id && item?.item) {
      item = item.item
    }
    const { logPageName, logEventName, hash } = item
    const menuItemProps = {
      plainText: item?.type === CategoryType.TEXT,
      href: item?.href ?? {
        query: { ...item?.query, contents: [item?.contentId] },
        hash,
      },
      logEventName,
      logPageName,
      onClick,
    }

    return (
      <Styled.MenuItem
        {...menuItemProps}
        isCurrentMenu={isCurrentMenu}
        autoBlur
      >
        {item?.label}
        {item?.type === CategoryType.RIGHT_LINK && (
          // IconType.IC_16_OUT_LINK 와 동일함. fill=currentColor 설정을 위해 직접 사용.
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ paddingLeft: 4 }}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12.8542 3.8484L3.70387 12.9987L3 12.2949L12.1503 3.14453L12.8542 3.8484Z'
              fill='currentColor'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12.0054 3.99543L12.0054 11.9442H13.0008L13.0008 3L4.05664 3L4.05664 3.99543L12.0054 3.99543Z'
              fill='currentColor'
            />
          </svg>
        )}
      </Styled.MenuItem>
    )
  },
  MobileMenuWrap({ categories, corporation, onOff, ...props }) {
    const { logLNBClick } = useMenuLogging()
    const [chosen, setChosen] = useState<number | null>(null)
    const handleChosenMenu = useCallback(
      (id) => {
        return () => {
          return setChosen((prevState) => {
            if (prevState === id) {
              return null
            }
            return id
          })
        }
      },
      [setChosen]
    )
    if (!categories?.length) {
      return null
    }

    const onMobileMenuClick = (item: { id: number; label: string }) => {
      logLNBClick(item)
      onOff()
    }

    return (
      <Styled.MobileMenuWrap
        role='navigation'
        aria-label='메인 메뉴'
        {...props}
      >
        <>
          {categories.map((item, index, array) => {
            if (item?.parentId || item.parentId !== null) {
              return null
            }
            const current = item?.id === chosen
            const children = array.filter(
              (childCandidate) => childCandidate?.parentId === item.id
            )
            const hasChildren = !!children?.length

            return (
              <Styled.MobileMenuGroup
                key={`LayoutHeader-mobileMenuWrap-${index}`}
              >
                <Styled.MobileMenuTitle>
                  <Styled.MobileMenuTitleTrigger
                    current={current}
                    onClick={hasChildren ? handleChosenMenu(item?.id) : onOff}
                    href={
                      hasChildren
                        ? null
                        : item?.href ?? {
                            query: {
                              ...item?.query,
                              contents: [item?.contentId],
                            },
                            hash: item?.hash,
                          }
                    }
                    plainText={hasChildren}
                    aria-expanded={current}
                  >
                    {item?.label}
                    {hasChildren &&
                      (current ? (
                        <Icon type={IconTypeFile.IC_16_UNFOLD} />
                      ) : (
                        <Icon type={IconTypeFile.IC_16_FOLD} />
                      ))}
                    {item?.type === CategoryType.RIGHT_LINK && (
                      <Icon type={IconTypeFile.IC_16_OUT_LINK} />
                    )}
                  </Styled.MobileMenuTitleTrigger>
                </Styled.MobileMenuTitle>
                {!!children.length && (
                  <Styled.MobileMenuContent current={current}>
                    {children.map((child, index) => (
                      <li key={index}>
                        <Styled.MobileMenuItem
                          key={`LayoutHeader-mobileMenuWrap-${index}`}
                          aria-hidden={!current}
                          tabIndex={current ? 0 : -1}
                          href={
                            child?.href ?? {
                              query: {
                                ...child?.query,
                                contents: [child?.contentId],
                              },
                              hash: child?.hash,
                            }
                          }
                          onClick={() => onMobileMenuClick(child)}
                        >
                          {child?.label}
                        </Styled.MobileMenuItem>
                      </li>
                    ))}
                  </Styled.MobileMenuContent>
                )}
              </Styled.MobileMenuGroup>
            )
          })}
          <Styled.MobileCite>&copy; {corporation}</Styled.MobileCite>
        </>
      </Styled.MobileMenuWrap>
    )
  },
}

function LayoutHeader({
  className,
  shrink = false,
  isHeaderTransparentAtTop = false,
}: LayoutHeaderProps) {
  const [scroll, setScroll] = useState(false)
  const [control, setControl] = useState(false)
  const [expand, setExpand] = useState(false)
  const { meta } = useMetaContent()
  const wrapRef = useRef<HTMLHeadingElement | null>(null)
  const mobileWrapRef = useRef<HTMLDivElement>(null)

  const listener = useCallback(() => {
    const pageYOffset = window.pageYOffset
    setScroll(
      (shrink && pageYOffset >= PC_HEADER_DEFAULT_TOP) ||
        pageYOffset >= PC_HEADER_MAIN_TOP
    )
  }, [shrink])

  useScrollEffect(listener)

  const LayoutHeaderStyleProps: LayoutHeaderStyleProps = {
    shrink,
    scroll,
    control,
    expand,
    isHeaderTransparentAtTop,
  }
  const handleOnControl = useCallback(() => {
    return setControl(true)
  }, [])
  const handleOffControl = useCallback(() => {
    return setControl(false)
  }, [])
  const handleToggleExpand = useCallback(() => {
    return setExpand((prevState) => !prevState)
  }, [])
  const handleOffExpand = useCallback(() => {
    wrapRef?.current?.blur?.()
    return setExpand(false)
  }, [wrapRef])

  useEffect(() => {
    window.document.documentElement.classList[expand ? 'add' : 'remove']?.(
      GLOBAL_HIDDEN_SCROLL_MOBILE_CLASS
    )
    if (expand && mobileWrapRef.current) {
      const undo = hideOthers(mobileWrapRef.current)
      return undo
    }
  }, [expand])

  return (
    <Styled.Wrap
      className={className}
      onMouseEnter={handleOnControl}
      onMouseLeave={handleOffControl}
      onBlur={handleOffControl}
      {...LayoutHeaderStyleProps}
      ref={wrapRef}
    >
      <Styled.Container {...LayoutHeaderStyleProps}>
        <Styled.Logo
          {...LayoutHeaderStyleProps}
          href={RouterMap.HOME}
          innerLink
        >
          <Hidden as='h1'>KakaoMobility</Hidden>
        </Styled.Logo>
        <FocusLock
          as={RenderOnly.Mobile}
          ref={mobileWrapRef}
          disabled={!expand}
        >
          <Styled.MobileTrigger onClick={handleToggleExpand}>
            <Hidden>{expand ? '접기' : '펼치기'}</Hidden>
            <Styled.MobileTriggerIcon {...LayoutHeaderStyleProps} />
          </Styled.MobileTrigger>
          {expand && (
            <Partial.MobileMenuWrap
              {...LayoutHeaderStyleProps}
              categories={meta.categories}
              corporation={meta.corporation}
              onOff={handleOffExpand}
            />
          )}
        </FocusLock>
        <OnlyClient>
          <Partial.Menu
            onLeave={handleOffControl}
            categories={meta.categories}
            {...LayoutHeaderStyleProps}
          />
        </OnlyClient>
      </Styled.Container>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.header<LayoutHeaderStyleProps>`
    ${WRAP_ACTIVE};
    height: 54px;
    z-index: 3000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: ${PC_HEADER_MAIN_HEIGHT}px;
    box-sizing: border-box;
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
    transition: all 0.2s ease;

    &:focus-within,
    &:hover {
      ${NORMAL_MENU_WRAP};

      ${MenuChildren} {
        ${EXPANDED_DEFAULT_MENU_CHILDREN};
        ${({ shrink, scroll }) =>
          (!shrink || !scroll) && EXPANDED_MAIN_MENU_CHILDREN}
      }

      ${Logo} {
        filter: none !important;
      }

      ${MobileTrigger} {
        &::before,
        &::after {
          border-color: ${STYLE_COLOR.BLACK01};
        }
      }
    }

    ${Logo} {
      ${({ shrink, scroll }) => {
        if (shrink && scroll) {
          return SHRINK_LOGO
        }
      }};
    }

    ${({ isHeaderTransparentAtTop, scroll, expand }) => {
      if (isHeaderTransparentAtTop && !scroll) {
        return css`
          ${TRANSPARENT_MENU_WRAP}; // 스크롤이 되지 않을 때 투명 배경
          ${Logo} {
            ${INVERTED_LOGO};
          }
          ${MobileTriggerIcon} {
            &::before,
            &::after {
              ${!expand &&
              css`
                border-color: ${STYLE_COLOR.WHITE01};
              `};
            }
          }
          &:focus-within,
          &:hover {
            ${MobileTriggerIcon} {
              &::before,
              &::after {
                border-color: ${STYLE_COLOR.BLACK01};
              }
            }
          }
        `
      }
      return null
    }};

    ${({ shrink, scroll, control, expand }) => {
      switch (true) {
        case shrink && scroll:
          return css`
            min-height: ${PC_HEADER_DEFAULT_HEIGHT}px;

            &::before {
              top: ${PC_HEADER_DEFAULT_HEIGHT}px !important;
            }

            ${MenuItem} {
              font-size: ${STYLE_FONT_SIZE._14};
              line-height: ${STYLE_LINE_HEIGHT._20};
              height: 100%;
            }
          `
        case expand:
          return css`
            @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
              bottom: 0;
            }
          `
      }
    }};

    ${MenuChildren} {
      ${EXPANDED_DEFAULT_MENU_CHILDREN};
      ${({ shrink, scroll }) =>
        (!shrink || !scroll) && EXPANDED_MAIN_MENU_CHILDREN}
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-height: ${MOBILE_HEADER_DEFAULT_HEIGHT}px;
      &::before {
        content: none;
      }
    }
  `,
  Container: styled.div<LayoutHeaderStyleProps>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    min-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH};
    max-width: ${STYLE_BREAKPOINT.PC_MAX_WIDTH};
    height: 100%;
    margin: auto;
    padding: 0 calc(5% + 6px);

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      justify-content: space-between;
      max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH};
      min-width: ${STYLE_BREAKPOINT.MOBILE_MIN_WIDTH};
    }
  `,
  Menu: styled(RenderOnly.Pc)<LayoutHeaderStyleProps>`
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: ${STYLE_FONT_SIZE._16};
    line-height: ${STYLE_LINE_HEIGHT._20};
    padding-right: 4px;

    .menuList {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }

    ${({ shrink, scroll }) =>
      (!shrink || !scroll) &&
      css`
        font-size: ${STYLE_FONT_SIZE._14};
      `}
    &::before {
      content: '';
      vertical-align: top;
      display: inline-block;
      height: 100%;
    }
  `,
  MenuGroup: styled.li<Partial<CategoryItemAll>>`
    position: relative;
    height: 100%;

    ${MenuChildren} {
      position: absolute;
      left: 0;
    }

    display: inline-block;
    vertical-align: top;
    text-align: left;
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
    ${({ type }) => {
      if (type === CategoryType.LINK) {
        return css`
          display: none;
        `
      }
    }};
    &:focus-within,
    &:hover {
      ul {
        display: block;
      }
    }
  `,
  MenuTitle: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    ${MenuItem} {
      padding-left: 32px;
      padding-right: 32px;
    }
  `,
  MobileMenuWrap: styled.ul<LayoutHeaderStyleProps>`
    ${STYLE_KEEP_WORD};
    position: fixed;
    top: ${MOBILE_HEADER_DEFAULT_HEIGHT}px;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    padding: 0 calc(5% + 6px);
    line-height: 55px;
    transition: opacity 0.1s ease-in-out;
    background-color: ${STYLE_COLOR.WHITE01};
  `,
  MobileMenuGroup: styled.li<LayoutHeaderStyleProps>`
    margin-top: 60px;

    & ~ & {
      margin-top: 0;
    }
  `,
  MobileMenuTitle: styled.div<LayoutHeaderStyleProps>`
    overflow: hidden;
  `,
  MobileMenuContent: styled.ul<LayoutHeaderStyleProps>`
    overflow: hidden;
    opacity: 0;
    height: 0;
    transition: opacity 0.1s ease-in-out;
    ${({ current }) =>
      current &&
      css`
        height: auto;
        opacity: 1;
        padding-bottom: 10px;
      `}
  `,
  MobileMenuTitleTrigger: styled(AutoLinkText)<LayoutHeaderStyleProps>`
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 19px 0;
    font-size: ${STYLE_FONT_SIZE._22};
    font-weight: ${STYLE_FONT_WEIGHT.EXTRA_BOLD};
    line-height: ${STYLE_LINE_HEIGHT._34};
    color: ${STYLE_COLOR.BLACK01};
    background: none transparent;
    border: 0 none;
    text-align: left;
    text-decoration: none !important;
    cursor: pointer;
  `,
  MobileMenuItem: styled(AutoLinkText)`
    display: block;
    padding: 10px 0;
    font-size: ${STYLE_FONT_SIZE._18};
    line-height: ${STYLE_LINE_HEIGHT._28};
    color: ${STYLE_COLOR.BLACK02};
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: ${STYLE_COLOR.BLACK01};
    }
  `,
  MobileCite: styled.cite`
    display: block;
    padding: 60px 0 40px;
    font-size: ${STYLE_FONT_SIZE._12};
    line-height: ${STYLE_LINE_HEIGHT._20};
    color: ${STYLE_COLOR.BLACK02};
  `,
  MobileTrigger,
  MobileTriggerIcon,
  MenuChildren,
  MenuItem,
  Logo,
}

export default LayoutHeader
