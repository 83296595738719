import { Hidden } from '@kakaomobility/tui-react'

export function convertTextWithHidden(text: string) {
  const matches = text.match(/<hidden>(.*?)<\/hidden>|([^<]+)/g)

  return matches.map((linkText) => {
    const match = linkText.match(/<hidden>(.*?)<\/hidden>/)
    if (match) {
      return <Hidden key={match[1]}>{match[1]}</Hidden>
    }
    return linkText
  })
}
