import { throttle } from 'lodash-es'
import { useEffect } from 'react'

export default function useScrollEffect(listener: () => void) {
  useEffect(() => {
    listener()
    const throttleListener = throttle(() => listener(), 300)
    window.addEventListener('scroll', throttleListener)
    window.addEventListener('resize', throttleListener)

    return () => {
      window.removeEventListener('scroll', throttleListener)
      window.removeEventListener('resize', throttleListener)
    }
  }, [listener])
}
