/**
 * @see https://nextjs.org/docs/api-reference/next/link
 * next.js 에서 Dynamic Route 사용 할 경우
 * - slug 를 path variable 치환 사용
 */
const RouterMap = {
  HOME: '/',
  CONTENT: '/[...contents]',
}

export default RouterMap
