import { useEffect, useState } from 'react'

/**
 * https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks 사이트 참고
 * mediaQueryString params 예시 - "(max-screen: 1024px)"
 */
export default function useMediaQuery(mediaQueryString: string) {
  const [isLoading, setIsLoading] = useState(true)
  const [matches, setMatches] = useState<boolean>(null)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString)
    const listener = () => setMatches(!!mediaQueryList.matches)
    listener()
    setIsLoading(false)
    mediaQueryList.addEventListener('change', listener) // updated from .addListener
    return () => mediaQueryList.removeEventListener('change', listener) // updated from .removeListener
  }, [mediaQueryString])

  return { isLoading, matches }
}
