export enum CategoryType {
  CONTENT,
  EVENT,
  LINK,
  TEXT,
  HASH,
  RIGHT_LINK,
}
export enum NoticeShowType {
  EVENT_HANDLER,
}
